<template>
  <div>
    <div class="text-right">
      <b-button
        variant="primary"
        :to="'/app/warehouses/add/'"
        class="mb-2"
      >
        <FeatherIcon icon="PlusIcon" /> Oluştur
      </b-button>
    </div>
    <b-card
      v-if="dataList.length > 0"
      no-body
    >
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(control)="data">
          <list-buttons
            primary-text="Düzenle"
            :primary-action="'/app/warehouses/edit/' + data.item.id"
            :delete-action="removeData"
            :data-id="data.item.id"
          />
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
    <b-alert
      v-else
      show
      variant="info"
      class="mt-1 mr-2 ml-2"
    >
      <div class="alert-body text-center">
        <div class="lead">
          Kayıtlı Depo Kartı Bulunamadı.
        </div>
        <div class="mt-1">
          <b-button
            variant="info"
            size="sm"
            to="/app/warehouses/add/"
          >
            Depo Kartı Oluştur
          </b-button>
        </div>
      </div>
    </b-alert>
  </div>
</template>
<script>

import {
  BCard, BTable, BButton, BCardFooter, BPagination, BAlert,
} from 'bootstrap-vue'
import ListButtons from '@/layouts/components/common/ListDropdownButtons.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const tableName = 'warehouses'

export default {
  name: 'Warehouses',
  components: {
    BAlert,
    ListButtons,
    BPagination,
    BCardFooter,
    BTable,
    BCard,
    BButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'wnumber',
          label: 'Depo Kodu',
          thClass: 'text-nowrap width-200',
        },
        {
          key: 'title',
          label: 'Depo',
        },
        {
          key: 'control',
          label: '',
          thClass: 'text-nowrap width-50',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.wnumber AS wnumber`,
          `${tableName}.title AS title`,
        ],
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['warehouses/dataList']
    },
    dataCounts() {
      return this.$store.getters['warehouses/dataCounts']
    },
    saveStatus() {
      return this.$store.getters['warehouses/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveStatus(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.getDataList()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('warehouses/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('warehouses/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },

}
</script>
